// Custom js for project

const sameHeight = () => {
  $('.same-height-item').matchHeight({
    byRow: true,
    property: 'height',
    target: null,
    remove: false
  });
}
export default sameHeight;
